@tailwind base;
@tailwind components;
@tailwind utilities;

model-viewer {
  width: 100%;
  height: 100%;
}

.slick-thumb {
    height: 4rem;
    width: 4rem;
}

.active {
    @apply p-1 border-solid border-2 border-[rgb(59,55,255)];
}